<template>
    <el-dialog :title="title" :visible="visible" @close="closeDialog" :width="width+'px'" append-to-body>
        <el-upload :show-file-list="false" drag with-credentials :on-success="onSuccess" :on-error="onError"
                   :on-progress="onProgress" accept=".xlsx,.xls" :action="axiosUtils.baseApi+action">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" :style="'width:'+(width-22)+'px'">请将文件拖到此处，或<em>点击上传</em></div>
            <div style="color: red;font-weight: bold;text-align: center" slot="tip">
                EXCEL导入必须严格遵循模板要求，否则将会导入失败，模板如下，
                <a :href="template" download=""><em>下载模板</em></a>
                <el-table id="table3" :data="data" style="width: 100%;" border size="mini">
                    <el-table-column
                            v-for="(item,index) in column"
                            v-if="item.needImport"
                            :key="index"
                            :show-overflow-tooltip="item.tooltip"
                            :prop="item.prop"
                            :label="item.label"
                            align="center"
                            :width="item.width">
                        <template slot-scope="scope">
                            <slot :row="scope.row" :column="scope.column">
                                <span style="color: red">{{scope.row[item.prop]}}</span>
                            </slot>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-upload>
    </el-dialog>
</template>

<script>

    export default {
        name: "uploadDialog",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            title: String,
            action: String,
            template: String,
            width: Number,
            data: Array,
            column: Array,
        },
        data() {
            return {
                myUtils: this.myUtils,
                axiosUtils: this.axiosUtils,
                moment: this.moment,
                templateUrl: ''
            }
        },
        mounted() {
            // this.axiosUtils.get(this.template).then(res => {
            //     this.templateUrl = res
            // })
        },
        methods: {
            closeDialog() {
                this.$emit('update:visible', false)
            },
            onSuccess(response, file, fileList) {
                this.$message.closeAll()
                if (response.code === 200) {
                    this.$message.success(response.msg)
                    this.$parent.getData()
                } else {
                    this.$message.error(response.msg)
                }
            },
            onError(err, file, fileList) {
                this.$message.closeAll()
                console.log(err)
                this.$message.error(err)
            },
            onProgress() {
                this.closeDialog()
                this.$message.info({
                    message: '上传中...',
                    duration: 0
                })
            },
        }
    }
</script>

<style scoped>

</style>