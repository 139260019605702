<template>
    <div v-if="isAdmin">
        <base-table :searchForm="searchForm" :searchValue="searchValue" :tableConfig="tableConfig"/>
        <el-dialog title="新建用户" :visible.sync="visible" width="400px" destroy-on-close @close="closeDialog"
                   :center="true">
            <!--:close-on-click-modal="false" :close-on-press-escape="false"-->
            <el-form :model="form" size="mini" label-width="100px" ref="ruleForm" :rules="rules">
                <el-form-item label="用户名：" prop="username">
                    <el-input v-model="form.username" clearable placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="名称：" prop="name">
                    <el-input v-model="form.name" clearable placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="手机：" prop="phone">
                    <el-input v-model="form.phone" clearable placeholder="请输入手机"></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="sex">
                    <el-radio-group v-model="form.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="0">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="权限：" prop="role">
                    <el-radio-group v-model="form.role">
                        <el-radio :label="1">管理员</el-radio>
                        <el-radio :label="3">业务员</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="账号状态：" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="0">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注：" prop="note">
                    <el-input type="textarea" :rows="2" v-model="form.note" clearable
                              placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog" size="mini">取 消</el-button>
                <el-button type="primary" @click="createForm" size="mini">确 定</el-button>
            </div>
        </el-dialog>
        <upload-dialog title="导入用户信息" action="importUsers" :visible.sync="uploadVisible" :width="800"
                       :data="exampleData" :column="tableConfig.column" :template="template"/>
    </div>
</template>

<script>
    import baseTable from "../../components/baseTable";
    import axiosUtils from "../../utils/axiosUtils";
    import uploadDialog from "../../components/uploadDialog";

    export default {
        name: "userList",
        components: {
            baseTable, uploadDialog
        },
        data() {
            return {
                visible: false,
                uploadVisible: false,
                exampleData: [{username: '必填项', name: '必填项'}],
                template:this.axiosUtils.baseApi+'upload/example.xlsx',
                form: {
                    username: '',
                    name: '',
                    phone: '',
                    role: 3,
                    sex: 1,
                    status: 1,
                    note: '',
                },
                searchForm: [{
                    type: 'input',
                    label: '姓名',
                    prop: 'name',
                    width: 120
                }, {
                    type: 'input',
                    label: '手机号',
                    prop: 'phone',
                    width: 120
                }, {
                    type: 'select',
                    label: '性别',
                    prop: 'sex',
                    width: 120,
                    list: [
                        {id: 1, name: '男'},
                        {id: 0, name: '女'},
                    ],
                }, {
                    type: 'select',
                    label: '权限',
                    prop: 'role',
                    width: 120,
                    list: [
                        {id: 1, name: '管理员'},
                        {id: 3, name: '业务员'},
                    ],
                }, {
                    type: 'select',
                    label: '状态',
                    prop: 'status',
                    width: 120,
                    list: [
                        {id: 1, name: '正常'},
                        {id: 0, name: '禁用'},
                    ],
                }, {
                    type: 'submit',
                    label: '查询',
                }, {
                    type: 'reset',
                    label: '重置',
                }],
                searchValue: {},
                tableConfig: {
                    needIndex: true,
                    header: '用户列表',
                    headerButton: [{
                        name: '新增',
                        click: this.openForm,
                        hidden: true
                    }, {
                        name: '批量导入',
                        click: this.importUsers,
                    },],
                    data: [],
                    column: [{
                        prop: "username",
                        label: "用户名",
                        align: 'center',
                        needImport: true
                    }, {
                        prop: "name",
                        label: "姓名",
                        align: 'center',
                        needImport: true
                    }, {
                        prop: "phone",
                        label: "手机",
                        align: 'center',
                        needImport: true
                    }, {
                        prop: "role",
                        label: "权限",
                        align: 'center',
                        needImport: false,
                        render: (text) => text === 1 ? '管理员' : '业务员'
                    }, {
                        prop: "sex",
                        label: "性别",
                        align: 'center',
                        needImport: true,
                        render: (text) => text === 0 ? '女' : '男'
                    }, {
                        prop: "createAt",
                        label: "创建日期",
                        align: 'center',
                        needImport: false,
                        width: 150
                    }, {
                        prop: "note",
                        label: "备注",
                        align: 'center',
                        needImport: true,
                    }, {
                        prop: "status",
                        label: "状态",
                        align: 'center',
                        needImport: false,
                        render: (text) => text === 1 ? '正常' : '禁用'
                    }],
                    actionWidth: 120,
                    action: [{
                        name: '编辑',
                        click: (record) => this.openForm(record),
                        hidden: (record) => record.id === 1
                    }, {
                        name: '重置',
                        click: (record) => this.resetUserPwd(record),
                        hidden: (record) => record.id === 1
                    }, {
                        name: '禁用',
                        click: (record) => this.updateUserStatus(record),
                        hidden: (record) => record.id === 1 || record.status === 0
                    }, {
                        name: '启用',
                        click: (record) => this.updateUserStatus(record),
                        hidden: (record) => record.id === 1 || record.status === 1
                    }],
                    pageConfig: {
                        pageSize: this.$route.query.pageSize || 10,
                        pageNum: this.$route.query.pageNum || 1,
                        total: 0
                    },
                },
                rules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入手机', trigger: 'blur'},
                    ],
                    sex: [
                        {required: true, message: '请选择性别', trigger: 'blur'},
                    ],
                    role: [
                        {required: true, message: '请选择权限', trigger: 'blur'},
                    ],
                    status: [
                        {required: true, message: '请选择状态', trigger: 'blur'},
                    ],
                },
                isAdmin: false
            }
        },
        mounted() {
            axiosUtils.get('isAdmin').then(res => {
                this.isAdmin = res
                this.tableConfig.headerButton[0].hidden = !res
                if (res) {
                    this.getData()
                }
            })
        },
        methods: {
            openForm(user) {
                if (this.isAdmin) {
                    if (user && user.id) {
                        axiosUtils.get('getUserDetails', {id: user.id}).then(res => {
                            this.form = res
                        })
                    }
                    this.visible = true
                }
            },
            createForm() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        axiosUtils.post('createUser', null, this.form).then(res => {
                            this.getData()
                            this.closeDialog()
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            refresh(){
                this.tableConfig.pageConfig.pageNum=1
                this.getData()
            },
            getData: function () {
                axiosUtils.get('getUserListPage', {
                    ...this.searchValue,
                    pageSize: this.tableConfig.pageConfig.pageSize,
                    pageCurrent: this.tableConfig.pageConfig.pageNum
                }).then(res => {
                    this.tableConfig.data = res.content
                    this.tableConfig.pageConfig.total = res.totalElements
                })
            },
            resetUserPwd(record) {
                if (this.isAdmin) {
                    this.$confirm('此操作将会重置该用户密码为123456', '重置密码', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        axiosUtils.get('resetPasswordById', {
                            id: record.id
                        }).then(res => {

                        })
                    });
                }
            },
            updateUserStatus(record) {
                axiosUtils.get('updateUserStatus', {
                    id: record.id
                }).then(res => {
                    record.status = res
                })
            },
            closeDialog() {
                this.visible = false
                this.form = {
                    username: '',
                    name: '',
                    phone: '',
                    role: 3,
                    sex: 1,
                    status: 1,
                    note: '',
                }
                this.$refs.ruleForm && this.$refs.ruleForm.clearValidate()
            },
            importUsers() {
                this.uploadVisible = true
            }
        }
    }
</script>

<style>
    .el-dialog--center .el-dialog__body {
        padding: 10px 10px 0 10px;
    }
</style>